import Swiper, { Pagination } from "swiper";
import PlaceSearch from "./../PlaceSearch";
import { renderStepper } from "./features/Stepper";
import { checkSteps } from "./features/Validation";
import { manageNavigation } from "./features/Navigation";
import { getRelations } from "./utils/getter";
import { Relations } from "./utils/types";
import { ui } from "./utils/ui";

const Guidance = () => {
    if (!ui?.module || !ui?.form) return;

    /**
     * Récupéation des relations entre les champs
     * ******************************
     */
    const relations: Relations = getRelations();

    /**
     * Initialisation du slider
     * ******************************
     */
    const steps = ui?.form;
    const page= document.querySelector("html") as HTMLElement;
    const slider: Swiper = new Swiper(ui.form, {
        allowTouchMove: false,
        autoHeight: true,
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 64,
        pagination: {
            el: ".swiper-pagination",
            type: "custom",
            renderCustom: (swiper, current) => renderStepper(swiper, current),
        },
        on: {
            slideChange: function() {
                steps.scrollTo(0, 0);
                page.scrollTo(0, 0);
            }}
    });

    if (slider?.slides?.length === 0) return;

    /**
     * Mise à jour et vérification des champs
     * ******************************
     */
    checkSteps(relations);

    /**
     * Gestion de la navigation entre les étapes
     * ******************************
     */
    manageNavigation(slider);

    /**
     * Gestion de la suggestion de lieux
     * ******************************
     */
    PlaceSearch(
        ui?.steps?.location?.geolocation,
        ui?.steps?.location?.field,
        ui?.steps?.location?.latitude,
        ui?.steps?.location?.longitude,
        ui?.steps?.location?.address,
        ui?.steps?.location?.postalCode,
        ui?.steps?.location?.city
    );
};

export default Guidance;
