/**
 * Import des librairies
 * ******************************
 */
// import "reset-css"

/**
 * Import des styles et des modules
 * ****************************************
 */
import "./../sass/style.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Home from "./modules/Home";
import Guidance from "./modules/Guidance";
import Catalog from "./modules/Catalog";
import Reviews from "./modules/Reviews";
import homeAnimation from "./modules/homeAnimation";
import menuMobile from "./modules/menuMobile";
import playerVideo from "./modules/playerVideo";
import sliders from "./modules/sliders";
import sliderAccompanying from "./modules/sliderAccompanying";
import StoreLocator from "./modules/storeLocator";
import typeTel from "./modules/typeTel";

/**
 * Initialisation des modules
 * ******************************
 */
window.onload = () => {
    Home();
    Guidance();
    Catalog();
    Reviews();
    homeAnimation();
    menuMobile();
    sliders();
    sliderAccompanying();
    playerVideo();
    StoreLocator();
    typeTel();
};
