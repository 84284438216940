import getData from "./getData"
import appendReviewsToBody from "./appendReviewsToBody"
import {Review} from "../utils/types"

let nextReviews: Review[] | null = null

let newDisplayedReviews = 4
export default (id: number, reviewsPerPage: number, loadMoreButton: HTMLButtonElement, body: HTMLDivElement) => {
    appendReviewsToBody(nextReviews, body)
    getData(id, newDisplayedReviews, reviewsPerPage)
        .then(data => {
            if (!data) return

            nextReviews = data?.data?.items
            newDisplayedReviews += reviewsPerPage

            if (!nextReviews || nextReviews.length === 0) {
                loadMoreButton.remove()
                return
            }
        })
}
