export const ui = {
    module: document.querySelector(".guidance") as HTMLDivElement,
    relations: document.querySelector(
        '.guidance input[name="relations"]'
    ) as HTMLInputElement,
    form: document.querySelector(".guidance .steps") as HTMLFormElement,
    steps: {
        controls: {
            prev: Array.from(
                document.querySelectorAll(
                    '.guidance .steps button[data-action="prev"]'
                ) as NodeListOf<HTMLInputElement>
            ),
            next: Array.from(
                document.querySelectorAll(
                    '.guidance .steps button[data-action="next"]'
                ) as NodeListOf<HTMLInputElement>
            ),
        },
        requirement: {
            slide: document.querySelector(
                '.guidance .steps [data-step="requirement"]'
            ) as HTMLDivElement,
            field: {
                options: Array.from(
                    document.querySelectorAll(
                        '.guidance .steps [data-step="requirement"] .requirements input'
                    ) as NodeListOf<HTMLInputElement>
                ),
                selected:
                    '.guidance .steps [data-step="requirement"] .requirements input:checked:not(:disabled)' as string,
            },
            controls: {
                next: document.querySelector(
                    '.guidance .steps [data-step="requirement"] button[data-action="next"]'
                ) as HTMLButtonElement,
            },
        },
        room: {
            slide: document.querySelector(
                '.guidance .steps [data-step="rooms"]'
            ) as HTMLDivElement,
            field: {
                options: Array.from(
                    document.querySelectorAll(
                        '.guidance .steps [data-step="room"] .rooms input'
                    ) as NodeListOf<HTMLInputElement>
                ),
                selected:
                    '.guidance .steps [data-step="room"] .rooms input:checked:not(:disabled)' as string,
            },
            controls: {
                next: document.querySelector(
                    '.guidance .steps [data-step="room"] button[data-action="next"]'
                ) as HTMLButtonElement,
            },
        },
        job: {
            slide: document.querySelector(
                '.guidance .steps [data-step="job"]'
            ) as HTMLDivElement,
            field: {
                options: Array.from(
                    document.querySelectorAll(
                        '.guidance .steps [data-step="job"] .jobs input'
                    ) as NodeListOf<HTMLInputElement>
                ),
                selected:
                    '.guidance .steps [data-step="job"] .jobs input:checked:not(:disabled)' as string,
            },
            controls: {
                next: document.querySelector(
                    '.guidance .steps [data-step="job"] button[data-action="next"]'
                ) as HTMLButtonElement,
            },
            empty: document.querySelector(
                    '.guidance .steps [data-step="job"] .empty-msg'
                ),
        },
        location: {
            slide: document.querySelector(
                '.guidance .steps [data-step="location"]'
            ) as HTMLDivElement,
            field: document.querySelector(
                '.guidance .steps [data-step="location"] .location input[name="location"]'
            ) as HTMLInputElement,
            geolocation: document.querySelector(
                '.guidance .steps [data-step="location"] .location button[name="geolocation"]'
            ) as HTMLButtonElement,
            longitude: document.querySelector(
                '.guidance .steps [data-step="location"] .location input[name="longitude"]'
            ) as HTMLInputElement,
            latitude: document.querySelector(
                '.guidance .steps [data-step="location"] .location input[name="latitude"]'
            ) as HTMLInputElement,
            address: document.querySelector(
                '.guidance .steps [data-step="location"] .location input[name="address"]'
            ) as HTMLInputElement,
            postalCode: document.querySelector(
                '.guidance .steps [data-step="location"] .location input[name="postal_code"]'
            ) as HTMLInputElement,
            city: document.querySelector(
                '.guidance .steps [data-step="location"] .location input[name="city"]'
            ) as HTMLInputElement,
            controls: {
                next: document.querySelector(
                    '.guidance .steps [data-step="location"] button[data-action="next"]'
                ) as HTMLButtonElement,
            },
        },
        period: {
            slide: document.querySelector(
                '.guidance .steps [data-step="period"]'
            ) as HTMLDivElement,
            field: {
                options: Array.from(
                    document.querySelectorAll(
                        '.guidance .steps [data-step="period"] .periods input'
                    ) as NodeListOf<HTMLInputElement>
                ),
                selected:
                    '.guidance .steps [data-step="period"] .periods input:checked:not(:disabled)' as string,
            },
            controls: {
                next: document.querySelector(
                    '.guidance .steps [data-step="period"] button[data-action="next"]'
                ) as HTMLButtonElement,
            },
        },
        status: {
            slide: document.querySelector(
                '.guidance .steps [data-step="status"]'
            ) as HTMLDivElement,
            field: {
                options: Array.from(
                    document.querySelectorAll(
                        '.guidance .steps [data-step="status"] .statutes input'
                    ) as NodeListOf<HTMLInputElement>
                ),
                selected:
                    '.guidance .steps [data-step="status"] .statutes input:checked:not(:disabled)' as string,
            },
            controls: {
                next: document.querySelector(
                    '.guidance .steps [data-step="status"] button[data-action="next"]'
                ) as HTMLButtonElement,
            },
        },
    },
};
