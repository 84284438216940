export default () => {
    const btn = document.querySelector(".type__buttons .btn[data-tel]") as HTMLAnchorElement

    if (!btn) return

    btn.onclick = () => {
        btn.innerText = btn.getAttribute("data-tel") as string
    }

    if (window.innerWidth < 900) {
        btn.href = "tel:" + btn.getAttribute("data-tel") as string
    }
}
