import { Loader } from "@googlemaps/js-api-loader";
import LocateMe from "./features/LocateMe";
import PopulateFields from "./features/PopulateFields";

const PlaceSearch = async (
    geolocationButton: HTMLButtonElement,
    searchField: HTMLInputElement,
    latitudeField: HTMLInputElement,
    longitudeField: HTMLInputElement,
    addressField?: HTMLInputElement,
    postalCodeField?: HTMLInputElement,
    cityField?: HTMLInputElement
) => {
    const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

    if (
        !googleMapsApiKey ||
        !geolocationButton ||
        !searchField ||
        !latitudeField ||
        !longitudeField
    )
        return;

    try {
        const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
            version: "weekly",
            libraries: ["places", "geocoding"],
        });

        const options = {
            componentRestrictions: { country: "fr" },
            fields: ["address_components", "geometry"],
        };

        const { Autocomplete } = await loader.importLibrary("places");
        const { Geocoder } = await loader.importLibrary("geocoding");

        const search = new Autocomplete(searchField, options);
        const geocoder = new Geocoder();

        if (!search || !geocoder) return;

        geolocationButton.onclick = (e) => {
            e.preventDefault();
            LocateMe(
                geocoder,
                searchField,
                latitudeField,
                longitudeField,
                addressField,
                postalCodeField,
                cityField
            );
        };

        google.maps.event.addListener(search, "place_changed", () => {
            const place = search.getPlace();
            PopulateFields(
                place,
                searchField,
                latitudeField,
                longitudeField,
                addressField,
                postalCodeField,
                cityField
            );
        });
    } catch (e) {
        console.error("Error loading places library:", e);
    }
};

export default PlaceSearch;
