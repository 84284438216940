export const ui = {
    module: document.querySelector(".catalogs") as HTMLDivElement,
    form: document.querySelector(
        ".catalogs .views-exposed-form"
    ) as HTMLFormElement,
    controls: {
        geolocation: document.querySelector(
            '.catalogs .views-exposed-form button[name="geolocation"]'
        ) as HTMLButtonElement,
    },
    fields: {
        search: document.querySelector(
            '.catalogs .views-exposed-form input[name="location"]'
        ) as HTMLInputElement,
        latitude: document.querySelector(
            '.catalogs .views-exposed-form input[name="coordinates[source_configuration][origin][lat]"]'
        ) as HTMLInputElement,
        longitude: document.querySelector(
            '.catalogs .views-exposed-form input[name="coordinates[source_configuration][origin][lon]"]'
        ) as HTMLInputElement,
        address: document.querySelector(
            '.catalogs .views-exposed-form input[name="address"]'
        ) as HTMLInputElement,
        postalCode: document.querySelector(
            '.catalogs .views-exposed-form input[name="postal_code"]'
        ) as HTMLInputElement,
        city: document.querySelector(
            '.catalogs .views-exposed-form input[name="city"]'
        ) as HTMLInputElement,
    },
};