import {getParams} from "./getParams";
import {stringify} from "qs";
import {ui} from "../utils/ui";

export const NavigateTo = (event: SubmitEvent) => {
    event.preventDefault()

    const {
        search: location,
        latitude,
        longitude,
        address,
        postal_code: postalCode,
        city,
    } = getParams()

    const params = {
        location: location,
        address: address,
        city: city,
        postal_code: postalCode,
        coordinates: {
            source_configuration: {
                origin: {
                    lon: longitude,
                    lat: latitude,
                },
            },
        },
    };

    const encodedParams = stringify(params, {
        encodeValuesOnly: true,
    });

    window.location.href = `${ui.form.dataset.goto}?${encodedParams}`;
}
