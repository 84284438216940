import { stringify } from "qs";
import Swiper from "swiper";
import { ui } from "../utils/ui";
import {
    getSelectedRequirement,
    getSelectedJobs,
    getSelectedRooms,
    getSelectedLocation,
    getSelectedPeriod,
    getSelectedStatus,
} from "../utils/getter";

/**
 * Gestion de la navigation entre les étapes
 * ******************************
 */
export const manageNavigation = (slider: Swiper) => {
    const prevs = ui?.steps?.controls?.prev;
    const nexts = ui?.steps?.controls?.next;
    const steps = ui?.form;
    const page= document.querySelector("html") as HTMLElement;

    prevs?.map(
        (prev) =>
            (prev.onclick = (e) => {
                e.preventDefault();
                slider.slidePrev();
                setTimeout(() =>{
                    // steps.scrollTo(0, 0);
                    // page.scrollTo(0, 0);
                }, 150)
            })
    );

    nexts?.map(
        (next) =>
            (next.onclick = (e) => {
                e.preventDefault();

                if (next.dataset.goto) {
                    const {
                        search: location,
                        latitude,
                        longitude,
                        address,
                        postal_code: postalCode,
                        city,
                    } = getSelectedLocation();
                    const params = {
                        project_type: getSelectedRequirement(),
                        rooms: getSelectedRooms(),
                        jobs: getSelectedJobs(),
                        status: getSelectedStatus(),
                        period: getSelectedPeriod(),
                        location: location,
                        address: address,
                        city: city,
                        postal_code: postalCode,
                        coordinates: {
                            source_configuration: {
                                origin: {
                                    lon: longitude,
                                    lat: latitude,
                                },
                            },
                        },
                    };

                    // Exhibition halls do not have a project type.
                    if(getSelectedStatus() == 'exhibition_hall') {
                        // @ts-ignore
                        delete params?.project_type;
                    }

                    const encodedParams = stringify(params, {
                        encodeValuesOnly: true,
                    });

                    const url = `${next.dataset.goto}?${encodedParams}`;

                    window.location.href = url;
                } else {
                    slider.slideNext();
                    setTimeout(() =>{
                        // steps.scrollTo(0, 0);
                        // page.scrrollTo(0, 0);
                        // page.scrollTo(0, 0);
                    }, 150)
                }
            })
    );
};
