const PopulateFields = (
    place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
    searchField: HTMLInputElement,
    latitudeField: HTMLInputElement,
    longitudeField: HTMLInputElement,
    addressField?: HTMLInputElement,
    postalCodeField?: HTMLInputElement,
    cityField?: HTMLInputElement
) => {
    latitudeField.setAttribute("value", "");
    longitudeField.setAttribute("value", "");
    addressField?.setAttribute("value", "");
    postalCodeField?.setAttribute("value", "");
    cityField?.setAttribute("value", "");

    const latitude = place?.geometry?.location?.lat();
    const longitude = place?.geometry?.location?.lng();

    if (!latitude || !longitude) return;

    latitudeField.value = String(latitude);
    longitudeField.value = String(longitude);

    const address = place?.formatted_address;

    if (address) {
        searchField.value = address;
    }

    const streetNumber = place?.address_components?.find((item) =>
        item?.types?.includes("street_number")
    )?.long_name;

    const route = place?.address_components?.find((item) =>
        item?.types?.includes("route")
    )?.long_name;

    const postalCode = place?.address_components?.find((item) =>
        item?.types?.includes("postal_code")
    )?.long_name;

    const city = place?.address_components?.find((item) =>
        item?.types?.includes("locality")
    )?.long_name;

    if (streetNumber && route && addressField) {
        addressField.value = `${streetNumber} ${route}`;
    }

    if (postalCode && postalCodeField) {
        postalCodeField.value = postalCode;
    }

    if (city && cityField) {
        cityField.value = city;
    }

    searchField.dispatchEvent(new Event("change"));
};

export default PopulateFields;
