import {
    getSelectedRequirement,
    getSelectedRooms,
    getSelectedJobs,
    getSelectedLocation,
    getSelectedPeriod,
    getSelectedStatus,
} from "../utils/getter";
import { Relations } from "../utils/types";
import { ui } from "../utils/ui";

/**
 * Mise à jour et vérification des champs
 * ******************************
 */
export const checkSteps = (relations: Relations) => {
    ui?.steps?.requirement?.field?.options?.map(
        (field) => (field.onchange = () => checkRequirement())
    );

    ui?.steps?.room?.field?.options?.map(
        (field) =>
            (field.onchange = () => {
                checkRooms();
                relations && updateJobs(relations);
            })
    );

    ui?.steps?.job?.field?.options?.map(
        (field) => (field.onchange = () => checkJobs())
    );

    ui?.steps?.location?.field &&
        ((ui.steps.location.field.oninput = () => cleanLocation()),
        (ui.steps.location.field.onchange = () => checkLocation()));

    ui?.steps?.period?.field?.options?.map(
        (field) => (field.onchange = () => checkPeriod())
    );

    ui?.steps?.status?.field?.options?.map(
        (field) => (field.onchange = () => checkStatus())
    );
};

/**
 * Mise à jour des métiers
 * ******************************
 */
export const updateJobs = (relations: Relations) => {
    if (!relations) return;

    const selectedRooms: string[] = getSelectedRooms();
    const options: string[] = [];

    for (const [job, rooms] of Object.entries(relations)) {
        if (rooms?.some((room) => selectedRooms.includes(room))) {
            options.push(job);
        }
    }

    options.length ? ui?.steps?.job?.empty?.classList.remove('active') : ui?.steps?.job?.empty?.classList.add('active');

    Array.from(ui?.steps?.job?.field?.options).map((job: HTMLInputElement) => {
        job.disabled = !options.includes(job.value);

        if (!job.disabled) {
            job.parentElement?.classList.add("selected");
        } else {
            job.parentElement?.classList.remove("selected");
        }

        job.dispatchEvent(new Event("change"));
    });
};

/**
 * Vérification du champ "Besoins"
 * ******************************
 */
export const checkRequirement = () => {
    const next = ui?.steps?.requirement?.controls?.next;
    const requirement: string = getSelectedRequirement();

    if (!next || requirement === null) return;

    if (requirement?.length > 0) {
        next.disabled = false;
    } else {
        next.disabled = true;
    }
};

/**
 * Vérification du champ "Pièces"
 * ******************************
 */
export const checkRooms = () => {
    const next = ui?.steps?.room?.controls?.next;
    const rooms: string[] = getSelectedRooms();

    if (!next || rooms === null) return;

    if (rooms?.length > 0) {
        next.disabled = false;
    } else {
        next.disabled = true;
    }
};

/**
 * Vérification du champ "Métiers"
 * ******************************
 */
export const checkJobs = () => {
    const next = ui?.steps?.job?.controls?.next;
    const jobs: string[] = getSelectedJobs();

    if (!next || jobs === null) return;

    if (jobs?.length > 0) {
        next.disabled = false;
    } else {
        next.disabled = true;
    }
};

/**
 * Vérification du champ "Emplacement"
 * ******************************
 */
export const checkLocation = () => {
    const next = ui?.steps?.location?.controls?.next;
    const location: Record<string, unknown> = getSelectedLocation();

    if (!next || location === null) return;

    if (location?.search && location?.latitude && location?.longitude) {
        next.disabled = false;
    } else {
        next.disabled = true;
    }
};

/**
 * Vérification du champ "Période"
 * ******************************
 */
export const checkPeriod = () => {
    const next = ui?.steps?.period?.controls?.next;
    const period: string = getSelectedPeriod();

    if (!next || period === null) return;

    if (period?.length > 0) {
        next.disabled = false;
    } else {
        next.disabled = true;
    }
};

/**
 * Vérification du champ "Progression"
 * ******************************
 */
export const checkStatus = () => {
    const next = ui?.steps?.status?.controls?.next;
    const status: string = getSelectedStatus();

    if (!next || status === null) return;

    if (status?.length > 0) {
        next.disabled = false;
    } else {
        next.disabled = true;
    }
};

/**
 * Réinitialisation des champs de la "Location"
 * ******************************
 */
export const cleanLocation = () => {
    ui?.steps?.location?.latitude.setAttribute("value", "");
    ui?.steps?.location?.longitude.setAttribute("value", "");
    ui?.steps?.location?.address.setAttribute("value", "");
    ui?.steps?.location?.postalCode.setAttribute("value", "");
    ui?.steps?.location?.city.setAttribute("value", "");
};
