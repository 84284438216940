import Swiper, {Pagination, Autoplay} from 'swiper'

export default () => {
    const fraction = document.getElementById("fraction")

    if (!fraction) return

    const swiper = new Swiper('.section--accompanying-keys .swiper', {
        modules: [Pagination, Autoplay],
        spaceBetween: 128,
        slidesPerView: "auto",
        autoplay: {
            delay: 3500,
        },
        pagination: {
            el: '.swiper-pagination-dots',
            clickable: true,
        },
        breakpoints: {
            900: {
                direction: 'vertical',
                slidesPerView: 1,
                spaceBetween: 128,
            },
        },

        on: {
            slideChange: () => {
                fraction.textContent = `0${swiper.realIndex + 1}`;
            }
        }
    })

    swiper.autoplay.stop()

    const section = document.querySelector('.section--accompanying-keys') as HTMLElement

    if (!section) return

    document.onscroll = () => {
        if (section.getBoundingClientRect().top < 300 && section.getBoundingClientRect().bottom > 800) {
            swiper.autoplay.start()
        }

        else {
            swiper.autoplay.stop()
        }
    }
}
