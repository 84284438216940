export default () => {
    const section = document.querySelector('.section--commitments')

    if (!section) return

    window.onscroll = () => {
        if (section.getBoundingClientRect().top < 500 && section.getBoundingClientRect().bottom > 500) {
            section.classList.add('animation')
        }
    }
}
