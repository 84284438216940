import {ui} from "../utils/ui";

export const getParams = (): Record<string, unknown> => {
    const search = ui?.fields?.search as HTMLInputElement;
    const latitude = ui?.fields?.latitude as HTMLInputElement;
    const longitude = ui?.fields?.longitude as HTMLInputElement;
    const address = ui?.fields?.address as HTMLInputElement;
    const postalCode = ui?.fields?.postalCode as HTMLInputElement;
    const city = ui?.fields?.city as HTMLInputElement;

    return {
        search: search?.value,
        latitude: latitude?.value,
        longitude: longitude?.value,
        address: address?.value,
        postal_code: postalCode?.value,
        city: city?.value,
    };
};
