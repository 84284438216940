export default function MenuMobile() {
    const popUp: HTMLDivElement | null = document.querySelector('.header__navigation')
    const switchs: NodeListOf<HTMLDivElement> | null = document.querySelectorAll('.menu__switch')
    const submenus: NodeListOf<HTMLSpanElement> | null = document.querySelectorAll('.menu__link--with-childs')
    const listSubmenus: NodeListOf<HTMLUListElement> | null = document.querySelectorAll('.menu__sublist')

    if (!popUp && !switchs) return

    switchs.forEach(item => {
        item.onclick = () => {
            popUp?.classList.toggle('open')
        }
    })

    submenus?.forEach((submenu, index) => {
        submenu.onclick = () => {
            listSubmenus[index].classList.toggle('active')
            submenus[index].classList.toggle('active')

            const activeMenu = listSubmenus[index]

            listSubmenus.forEach((listSubmenu, index) => {
                if (listSubmenu !== activeMenu) {
                    listSubmenu.classList.remove('active')
                    submenus[index].classList.remove('active')
                }
            })
        }

        window.onclick = e => {
            const elem = e.target as HTMLElement;
            if (!elem.closest('.menu__item--expanded')) {
                listSubmenus.forEach((item, index) => {
                    item.classList.remove('active')
                    submenus[index].classList.remove('active')
                })
            }
        }
    })
}
