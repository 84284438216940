import PlaceSearch from "./../PlaceSearch";
import {ui} from "./utils/ui";
import {getParams} from "./features/getParams";
import {stringify} from "qs";
import {NavigateTo} from "./features/navigateTo";

const Home = () => {
    if (!ui?.module || !ui?.form) return;

    PlaceSearch(
        ui?.controls?.geolocation,
        ui?.fields?.search,
        ui?.fields?.latitude,
        ui?.fields?.longitude,
        ui?.fields?.address,
        ui?.fields?.postalCode,
        ui?.fields?.city
    );


    ui.form.addEventListener("submit", (event: SubmitEvent) => {
        NavigateTo(event);
    })
};

export default Home;
