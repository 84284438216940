import loadMoreReviews from "./loadMoreReviews"

export default (id: number, reviewsPerPage: number, section: HTMLElement, body: HTMLDivElement) => {
    const loadMoreButton = document.createElement('button')
    loadMoreButton.innerHTML = `Afficher plus d’avis <svg class="icon"><use xlink:href="#arrow-down"/></svg>`
    loadMoreButton.addEventListener('click', () => loadMoreReviews(id, reviewsPerPage, loadMoreButton, body))
    section?.appendChild(loadMoreButton)

    return loadMoreButton
}
