import { ui } from "./ui";
import { Relations } from "./types";

export const getRelations = (): Relations => {
    return JSON.parse(ui?.relations?.value);
};

export const getSelectedRequirement = (): string => {
    const selectedOption = ui?.module.querySelector(
        ui?.steps?.requirement?.field?.selected
    ) as HTMLInputElement;

    return selectedOption?.value;
};

export const getSelectedRooms = (): string[] => {
    const selectedOptions = Array.from(
        ui?.module.querySelectorAll(
            ui?.steps?.room?.field?.selected
        ) as NodeListOf<HTMLInputElement>
    );

    return selectedOptions?.map((option) => option?.value);
};

export const getSelectedJobs = (): string[] => {
    const selectedOptions = Array.from(
        ui?.module.querySelectorAll(
            ui?.steps?.job?.field?.selected
        ) as NodeListOf<HTMLInputElement>
    );

    return selectedOptions?.map((option) => option?.value);
};

export const getSelectedLocation = (): Record<string, unknown> => {
    const selectedOption = ui?.steps?.location?.field as HTMLInputElement;
    const latitude = ui?.steps?.location?.latitude as HTMLInputElement;
    const longitude = ui?.steps?.location?.longitude as HTMLInputElement;
    const address = ui?.steps?.location?.address as HTMLInputElement;
    const postalCode = ui?.steps?.location?.postalCode as HTMLInputElement;
    const city = ui?.steps?.location?.city as HTMLInputElement;

    return {
        search: selectedOption?.value,
        latitude: latitude?.value,
        longitude: longitude?.value,
        address: address?.value,
        postal_code: postalCode?.value,
        city: city?.value,
    };
};

export const getSelectedPeriod = (): string => {
    const selectedOption = ui?.module.querySelector(
        ui?.steps?.period?.field?.selected
    ) as HTMLInputElement;

    return selectedOption?.value;
};

export const getSelectedStatus = (): string => {
    const selectedOption = ui?.module.querySelector(
        ui?.steps?.status?.field?.selected
    ) as HTMLInputElement;

    return selectedOption?.value;
};
