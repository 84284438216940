import {ui} from "./utils/ui"
import loadFirstReviews from "./features/loadFirstReviews"
import getId from "./features/getId"

export default () => {
    const id = getId()

    if (!ui?.module || !id) return ui?.module?.remove()

    loadFirstReviews(id, 1, 3, ui?.module, ui?.container?.header, ui?.container?.body)
}
