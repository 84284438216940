import Swiper from "swiper";

/**
 * Création d'un stepper personnalisé
 * ******************************
 */
export const renderStepper = (swiper: Swiper, current: number) =>
    swiper?.slides
        ?.map((slide, index) => {
            const label =
                (slide as HTMLElement)?.dataset.stepLabel ?? index + 1;
            const classes = `swiper-pagination-item${
                current >= index + 1 ? " active" : ""
            }`;
            return `<span class="${classes}">${label}</span>`;
        })
        ?.join("");
