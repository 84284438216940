export const ui = {
    module: document.querySelector(
        ".section--reviews"
    ) as HTMLElement,
    container: {
        header: document.querySelector(
            '.section--reviews .section--reviews__header'
        ) as HTMLDivElement,
        body: document.querySelector(
            '.section--reviews .section--reviews__body'
        ) as HTMLDivElement,
    },
}
