import PopulateFields from "./PopulateFields";

const LocateMe = async (
    geocoder: google.maps.Geocoder,
    searchField: HTMLInputElement,
    latitudeField: HTMLInputElement,
    longitudeField: HTMLInputElement,
    addressField?: HTMLInputElement,
    postalCodeField?: HTMLInputElement,
    cityField?: HTMLInputElement
) => {
    if (!navigator.geolocation) {
        alert("Geolocation is not supported by your browser.");
        return;
    }

    try {
        const position = await new Promise<GeolocationPosition>(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            }
        );

        const { latitude, longitude } = position.coords;

        if (!latitude || !longitude) return;

        geocoder.geocode(
            { address: `${latitude},${longitude}` },
            (results, status) => {
                if (status !== google.maps.GeocoderStatus.OK) {
                    console.error("Geocoder failed due to: " + status);
                    return;
                }

                if (!Array.isArray(results) || !results.length) {
                    console.error("No results found");
                    return;
                }

                PopulateFields(
                    results[0],
                    searchField,
                    latitudeField,
                    longitudeField,
                    addressField,
                    postalCodeField,
                    cityField
                );
            }
        );
    } catch (error) {
        console.error("Error getting location: ", error);
    }
};

export default LocateMe;
