import Swiper, {Navigation, Pagination} from "swiper";

export default () => {
    const ressources: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        ".section--ressources .swiper"
    );

    const types: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        ".section--types .swiper"
    );

    const pushSlider: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        ".push-slider .swiper"
    );

    const news: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        ".section--type-news .swiper"
    );

    const latestRessouces: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        ".slider--latest-ressources.swiper"
    );

    const achievementsInArtisan: NodeListOf<HTMLDivElement> = document.querySelectorAll(
        ".section--achievements-in-artisan .swiper"
    );

    const allSliders = [
        ressources,
        types,
        pushSlider,
        news,
        latestRessouces,
        achievementsInArtisan
    ]

    allSliders.forEach((sliders) => {
        sliders.forEach((slider) => {
            new Swiper(slider, {
                modules: [Navigation, Pagination],
                spaceBetween: 16,
                slidesPerView: "auto",
                breakpoints: {
                    900: {
                        slidesPerView: 2,
                    },
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
        });
    });
};
